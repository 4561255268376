import { default as _91_46_46_46404_93fnq6Sm7twMMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/[...404].vue?macro=true";
import { default as activatebnr2z5uksmMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/activate.vue?macro=true";
import { default as activityRq82kwYp9FMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/activity.vue?macro=true";
import { default as contactNV86YBFkd6Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/contact.vue?macro=true";
import { default as indexLBCJU2LZasMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/cookies-management/index.vue?macro=true";
import { default as index2K6mvgP1BJMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/correlyce/index.vue?macro=true";
import { default as edit_45profileZ7eUREDtVgMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/edit-profile.vue?macro=true";
import { default as not_45foundUxzSLQndwHMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/not-found.vue?macro=true";
import { default as _91token_93dJQRtSspzaMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/required/[token].vue?macro=true";
import { default as _91token_938BfYbhqmwWMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/success/[token].vue?macro=true";
import { default as faqspelvDiixbrMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/faqs.vue?macro=true";
import { default as indextg7duepunAMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/index.vue?macro=true";
import { default as redirect8zVNNhIZ9kMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/redirect.vue?macro=true";
import { default as indexXZYzHytSb0Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/index.vue?macro=true";
import { default as landingTMUvWStXAtMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/landing.vue?macro=true";
import { default as loginnWWtDVY6PNMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/login.vue?macro=true";
import { default as my_45community73ShHgDcrLMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/my-community.vue?macro=true";
import { default as _91_91uuid_93_93QfLGsIEhwmMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/my-messages/[[uuid]].vue?macro=true";
import { default as my_45profilea4hZbE51ugMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/my-profile.vue?macro=true";
import { default as forgotStikWUYtmTMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/password/forgot.vue?macro=true";
import { default as _91token_93CAAeduVsKoMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/password/reset/[token].vue?macro=true";
import { default as _91uuid_93h2lfz2LCdIMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/profile/[uuid].vue?macro=true";
import { default as register_45codeJOZJaiUxwiMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/register-code.vue?macro=true";
import { default as searchbRlcpZUwqoMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/search.vue?macro=true";
import { default as classancW4OV5I2Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/student/class.vue?macro=true";
import { default as correspondent_45classFylin3qui0Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/student/correspondent-class.vue?macro=true";
import { default as workspaceQxXGlraAv5Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/student/workspace.vue?macro=true";
import { default as add_45studentso4CyRqsrH9Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/add-students.vue?macro=true";
import { default as class_45tests34EwswhrTUMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class-tests.vue?macro=true";
import { default as classDg1iEvfYfKMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class.vue?macro=true";
import { default as correspondent_45classG6CoGlXERaMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/correspondent-class.vue?macro=true";
import { default as create_45correspondent_45classh3Z8JX82TfMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-correspondent-class.vue?macro=true";
import { default as create_45work_45groupPXYV2ofRnGMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-work-group.vue?macro=true";
import { default as edit_45activityRK523ShZ4mMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-activity.vue?macro=true";
import { default as edit_45work_45groupkXAR0DAvR7Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-work-group.vue?macro=true";
import { default as manage_45studentsFMSQ5ww6sNMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/manage-students.vue?macro=true";
import { default as workspace1ojPHFwcD5Meta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/workspace.vue?macro=true";
import { default as _91_91id_93_93pO43DLnzNdMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/tests/[[iso]]/[[id]].vue?macro=true";
import { default as wizardZ4TFdZqD9XMeta } from "/var/www/vhosts/beegup.com/app.beegup.com/pages/wizard.vue?macro=true";
export default [
  {
    name: _91_46_46_46404_93fnq6Sm7twMMeta?.name ?? "404___en",
    path: _91_46_46_46404_93fnq6Sm7twMMeta?.path ?? "/en/:404(.*)*",
    meta: _91_46_46_46404_93fnq6Sm7twMMeta || {},
    alias: _91_46_46_46404_93fnq6Sm7twMMeta?.alias || [],
    redirect: _91_46_46_46404_93fnq6Sm7twMMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46404_93fnq6Sm7twMMeta?.name ?? "404___de",
    path: _91_46_46_46404_93fnq6Sm7twMMeta?.path ?? "/de/:404(.*)*",
    meta: _91_46_46_46404_93fnq6Sm7twMMeta || {},
    alias: _91_46_46_46404_93fnq6Sm7twMMeta?.alias || [],
    redirect: _91_46_46_46404_93fnq6Sm7twMMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46404_93fnq6Sm7twMMeta?.name ?? "404___es",
    path: _91_46_46_46404_93fnq6Sm7twMMeta?.path ?? "/es/:404(.*)*",
    meta: _91_46_46_46404_93fnq6Sm7twMMeta || {},
    alias: _91_46_46_46404_93fnq6Sm7twMMeta?.alias || [],
    redirect: _91_46_46_46404_93fnq6Sm7twMMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46404_93fnq6Sm7twMMeta?.name ?? "404___fr",
    path: _91_46_46_46404_93fnq6Sm7twMMeta?.path ?? "/fr/:404(.*)*",
    meta: _91_46_46_46404_93fnq6Sm7twMMeta || {},
    alias: _91_46_46_46404_93fnq6Sm7twMMeta?.alias || [],
    redirect: _91_46_46_46404_93fnq6Sm7twMMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: activatebnr2z5uksmMeta?.name ?? "activate___en",
    path: activatebnr2z5uksmMeta?.path ?? "/en/activate",
    meta: activatebnr2z5uksmMeta || {},
    alias: activatebnr2z5uksmMeta?.alias || [],
    redirect: activatebnr2z5uksmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: activatebnr2z5uksmMeta?.name ?? "activate___de",
    path: activatebnr2z5uksmMeta?.path ?? "/de/activate",
    meta: activatebnr2z5uksmMeta || {},
    alias: activatebnr2z5uksmMeta?.alias || [],
    redirect: activatebnr2z5uksmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: activatebnr2z5uksmMeta?.name ?? "activate___es",
    path: activatebnr2z5uksmMeta?.path ?? "/es/activate",
    meta: activatebnr2z5uksmMeta || {},
    alias: activatebnr2z5uksmMeta?.alias || [],
    redirect: activatebnr2z5uksmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: activatebnr2z5uksmMeta?.name ?? "activate___fr",
    path: activatebnr2z5uksmMeta?.path ?? "/fr/activate",
    meta: activatebnr2z5uksmMeta || {},
    alias: activatebnr2z5uksmMeta?.alias || [],
    redirect: activatebnr2z5uksmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: activityRq82kwYp9FMeta?.name ?? "activity___en",
    path: activityRq82kwYp9FMeta?.path ?? "/en/activity",
    meta: activityRq82kwYp9FMeta || {},
    alias: activityRq82kwYp9FMeta?.alias || [],
    redirect: activityRq82kwYp9FMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: activityRq82kwYp9FMeta?.name ?? "activity___de",
    path: activityRq82kwYp9FMeta?.path ?? "/de/activity",
    meta: activityRq82kwYp9FMeta || {},
    alias: activityRq82kwYp9FMeta?.alias || [],
    redirect: activityRq82kwYp9FMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: activityRq82kwYp9FMeta?.name ?? "activity___es",
    path: activityRq82kwYp9FMeta?.path ?? "/es/activity",
    meta: activityRq82kwYp9FMeta || {},
    alias: activityRq82kwYp9FMeta?.alias || [],
    redirect: activityRq82kwYp9FMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: activityRq82kwYp9FMeta?.name ?? "activity___fr",
    path: activityRq82kwYp9FMeta?.path ?? "/fr/activity",
    meta: activityRq82kwYp9FMeta || {},
    alias: activityRq82kwYp9FMeta?.alias || [],
    redirect: activityRq82kwYp9FMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: contactNV86YBFkd6Meta?.name ?? "contact___en",
    path: contactNV86YBFkd6Meta?.path ?? "/en/contact",
    meta: contactNV86YBFkd6Meta || {},
    alias: contactNV86YBFkd6Meta?.alias || [],
    redirect: contactNV86YBFkd6Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNV86YBFkd6Meta?.name ?? "contact___de",
    path: contactNV86YBFkd6Meta?.path ?? "/de/contact",
    meta: contactNV86YBFkd6Meta || {},
    alias: contactNV86YBFkd6Meta?.alias || [],
    redirect: contactNV86YBFkd6Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNV86YBFkd6Meta?.name ?? "contact___es",
    path: contactNV86YBFkd6Meta?.path ?? "/es/contact",
    meta: contactNV86YBFkd6Meta || {},
    alias: contactNV86YBFkd6Meta?.alias || [],
    redirect: contactNV86YBFkd6Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNV86YBFkd6Meta?.name ?? "contact___fr",
    path: contactNV86YBFkd6Meta?.path ?? "/fr/contact",
    meta: contactNV86YBFkd6Meta || {},
    alias: contactNV86YBFkd6Meta?.alias || [],
    redirect: contactNV86YBFkd6Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexLBCJU2LZasMeta?.name ?? "cookies-management___en",
    path: indexLBCJU2LZasMeta?.path ?? "/en/cookies-management",
    meta: indexLBCJU2LZasMeta || {},
    alias: indexLBCJU2LZasMeta?.alias || [],
    redirect: indexLBCJU2LZasMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/cookies-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexLBCJU2LZasMeta?.name ?? "cookies-management___de",
    path: indexLBCJU2LZasMeta?.path ?? "/de/cookies-management",
    meta: indexLBCJU2LZasMeta || {},
    alias: indexLBCJU2LZasMeta?.alias || [],
    redirect: indexLBCJU2LZasMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/cookies-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexLBCJU2LZasMeta?.name ?? "cookies-management___es",
    path: indexLBCJU2LZasMeta?.path ?? "/es/cookies-management",
    meta: indexLBCJU2LZasMeta || {},
    alias: indexLBCJU2LZasMeta?.alias || [],
    redirect: indexLBCJU2LZasMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/cookies-management/index.vue").then(m => m.default || m)
  },
  {
    name: indexLBCJU2LZasMeta?.name ?? "cookies-management___fr",
    path: indexLBCJU2LZasMeta?.path ?? "/fr/cookies-management",
    meta: indexLBCJU2LZasMeta || {},
    alias: indexLBCJU2LZasMeta?.alias || [],
    redirect: indexLBCJU2LZasMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/cookies-management/index.vue").then(m => m.default || m)
  },
  {
    name: index2K6mvgP1BJMeta?.name ?? "correlyce___en",
    path: index2K6mvgP1BJMeta?.path ?? "/en/correlyce",
    meta: index2K6mvgP1BJMeta || {},
    alias: index2K6mvgP1BJMeta?.alias || [],
    redirect: index2K6mvgP1BJMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/correlyce/index.vue").then(m => m.default || m)
  },
  {
    name: index2K6mvgP1BJMeta?.name ?? "correlyce___de",
    path: index2K6mvgP1BJMeta?.path ?? "/de/correlyce",
    meta: index2K6mvgP1BJMeta || {},
    alias: index2K6mvgP1BJMeta?.alias || [],
    redirect: index2K6mvgP1BJMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/correlyce/index.vue").then(m => m.default || m)
  },
  {
    name: index2K6mvgP1BJMeta?.name ?? "correlyce___es",
    path: index2K6mvgP1BJMeta?.path ?? "/es/correlyce",
    meta: index2K6mvgP1BJMeta || {},
    alias: index2K6mvgP1BJMeta?.alias || [],
    redirect: index2K6mvgP1BJMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/correlyce/index.vue").then(m => m.default || m)
  },
  {
    name: index2K6mvgP1BJMeta?.name ?? "correlyce___fr",
    path: index2K6mvgP1BJMeta?.path ?? "/fr/correlyce",
    meta: index2K6mvgP1BJMeta || {},
    alias: index2K6mvgP1BJMeta?.alias || [],
    redirect: index2K6mvgP1BJMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/correlyce/index.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileZ7eUREDtVgMeta?.name ?? "edit-profile___en",
    path: edit_45profileZ7eUREDtVgMeta?.path ?? "/en/edit-profile",
    meta: edit_45profileZ7eUREDtVgMeta || {},
    alias: edit_45profileZ7eUREDtVgMeta?.alias || [],
    redirect: edit_45profileZ7eUREDtVgMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileZ7eUREDtVgMeta?.name ?? "edit-profile___de",
    path: edit_45profileZ7eUREDtVgMeta?.path ?? "/de/edit-profile",
    meta: edit_45profileZ7eUREDtVgMeta || {},
    alias: edit_45profileZ7eUREDtVgMeta?.alias || [],
    redirect: edit_45profileZ7eUREDtVgMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileZ7eUREDtVgMeta?.name ?? "edit-profile___es",
    path: edit_45profileZ7eUREDtVgMeta?.path ?? "/es/edit-profile",
    meta: edit_45profileZ7eUREDtVgMeta || {},
    alias: edit_45profileZ7eUREDtVgMeta?.alias || [],
    redirect: edit_45profileZ7eUREDtVgMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileZ7eUREDtVgMeta?.name ?? "edit-profile___fr",
    path: edit_45profileZ7eUREDtVgMeta?.path ?? "/fr/edit-profile",
    meta: edit_45profileZ7eUREDtVgMeta || {},
    alias: edit_45profileZ7eUREDtVgMeta?.alias || [],
    redirect: edit_45profileZ7eUREDtVgMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: not_45foundUxzSLQndwHMeta?.name ?? "email-activation-not-found___en",
    path: not_45foundUxzSLQndwHMeta?.path ?? "/en/email-activation/not-found",
    meta: not_45foundUxzSLQndwHMeta || {},
    alias: not_45foundUxzSLQndwHMeta?.alias || [],
    redirect: not_45foundUxzSLQndwHMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/not-found.vue").then(m => m.default || m)
  },
  {
    name: not_45foundUxzSLQndwHMeta?.name ?? "email-activation-not-found___de",
    path: not_45foundUxzSLQndwHMeta?.path ?? "/de/email-activation/not-found",
    meta: not_45foundUxzSLQndwHMeta || {},
    alias: not_45foundUxzSLQndwHMeta?.alias || [],
    redirect: not_45foundUxzSLQndwHMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/not-found.vue").then(m => m.default || m)
  },
  {
    name: not_45foundUxzSLQndwHMeta?.name ?? "email-activation-not-found___es",
    path: not_45foundUxzSLQndwHMeta?.path ?? "/es/email-activation/not-found",
    meta: not_45foundUxzSLQndwHMeta || {},
    alias: not_45foundUxzSLQndwHMeta?.alias || [],
    redirect: not_45foundUxzSLQndwHMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/not-found.vue").then(m => m.default || m)
  },
  {
    name: not_45foundUxzSLQndwHMeta?.name ?? "email-activation-not-found___fr",
    path: not_45foundUxzSLQndwHMeta?.path ?? "/fr/email-activation/not-found",
    meta: not_45foundUxzSLQndwHMeta || {},
    alias: not_45foundUxzSLQndwHMeta?.alias || [],
    redirect: not_45foundUxzSLQndwHMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/not-found.vue").then(m => m.default || m)
  },
  {
    name: _91token_93dJQRtSspzaMeta?.name ?? "email-activation-required-token___en",
    path: _91token_93dJQRtSspzaMeta?.path ?? "/en/email-activation/required/:token()",
    meta: _91token_93dJQRtSspzaMeta || {},
    alias: _91token_93dJQRtSspzaMeta?.alias || [],
    redirect: _91token_93dJQRtSspzaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/required/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93dJQRtSspzaMeta?.name ?? "email-activation-required-token___de",
    path: _91token_93dJQRtSspzaMeta?.path ?? "/de/email-activation/required/:token()",
    meta: _91token_93dJQRtSspzaMeta || {},
    alias: _91token_93dJQRtSspzaMeta?.alias || [],
    redirect: _91token_93dJQRtSspzaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/required/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93dJQRtSspzaMeta?.name ?? "email-activation-required-token___es",
    path: _91token_93dJQRtSspzaMeta?.path ?? "/es/email-activation/required/:token()",
    meta: _91token_93dJQRtSspzaMeta || {},
    alias: _91token_93dJQRtSspzaMeta?.alias || [],
    redirect: _91token_93dJQRtSspzaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/required/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93dJQRtSspzaMeta?.name ?? "email-activation-required-token___fr",
    path: _91token_93dJQRtSspzaMeta?.path ?? "/fr/email-activation/required/:token()",
    meta: _91token_93dJQRtSspzaMeta || {},
    alias: _91token_93dJQRtSspzaMeta?.alias || [],
    redirect: _91token_93dJQRtSspzaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/required/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_938BfYbhqmwWMeta?.name ?? "email-activation-success-token___en",
    path: _91token_938BfYbhqmwWMeta?.path ?? "/en/email-activation/success/:token()",
    meta: _91token_938BfYbhqmwWMeta || {},
    alias: _91token_938BfYbhqmwWMeta?.alias || [],
    redirect: _91token_938BfYbhqmwWMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/success/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_938BfYbhqmwWMeta?.name ?? "email-activation-success-token___de",
    path: _91token_938BfYbhqmwWMeta?.path ?? "/de/email-activation/success/:token()",
    meta: _91token_938BfYbhqmwWMeta || {},
    alias: _91token_938BfYbhqmwWMeta?.alias || [],
    redirect: _91token_938BfYbhqmwWMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/success/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_938BfYbhqmwWMeta?.name ?? "email-activation-success-token___es",
    path: _91token_938BfYbhqmwWMeta?.path ?? "/es/email-activation/success/:token()",
    meta: _91token_938BfYbhqmwWMeta || {},
    alias: _91token_938BfYbhqmwWMeta?.alias || [],
    redirect: _91token_938BfYbhqmwWMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/success/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_938BfYbhqmwWMeta?.name ?? "email-activation-success-token___fr",
    path: _91token_938BfYbhqmwWMeta?.path ?? "/fr/email-activation/success/:token()",
    meta: _91token_938BfYbhqmwWMeta || {},
    alias: _91token_938BfYbhqmwWMeta?.alias || [],
    redirect: _91token_938BfYbhqmwWMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/email-activation/success/[token].vue").then(m => m.default || m)
  },
  {
    name: faqspelvDiixbrMeta?.name ?? "faqs___en",
    path: faqspelvDiixbrMeta?.path ?? "/en/faqs",
    meta: faqspelvDiixbrMeta || {},
    alias: faqspelvDiixbrMeta?.alias || [],
    redirect: faqspelvDiixbrMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqspelvDiixbrMeta?.name ?? "faqs___de",
    path: faqspelvDiixbrMeta?.path ?? "/de/faqs",
    meta: faqspelvDiixbrMeta || {},
    alias: faqspelvDiixbrMeta?.alias || [],
    redirect: faqspelvDiixbrMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqspelvDiixbrMeta?.name ?? "faqs___es",
    path: faqspelvDiixbrMeta?.path ?? "/es/faqs",
    meta: faqspelvDiixbrMeta || {},
    alias: faqspelvDiixbrMeta?.alias || [],
    redirect: faqspelvDiixbrMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: faqspelvDiixbrMeta?.name ?? "faqs___fr",
    path: faqspelvDiixbrMeta?.path ?? "/fr/faqs",
    meta: faqspelvDiixbrMeta || {},
    alias: faqspelvDiixbrMeta?.alias || [],
    redirect: faqspelvDiixbrMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: indextg7duepunAMeta?.name ?? "gar___en",
    path: indextg7duepunAMeta?.path ?? "/en/gar",
    meta: indextg7duepunAMeta || {},
    alias: indextg7duepunAMeta?.alias || [],
    redirect: indextg7duepunAMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/index.vue").then(m => m.default || m)
  },
  {
    name: indextg7duepunAMeta?.name ?? "gar___de",
    path: indextg7duepunAMeta?.path ?? "/de/gar",
    meta: indextg7duepunAMeta || {},
    alias: indextg7duepunAMeta?.alias || [],
    redirect: indextg7duepunAMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/index.vue").then(m => m.default || m)
  },
  {
    name: indextg7duepunAMeta?.name ?? "gar___es",
    path: indextg7duepunAMeta?.path ?? "/es/gar",
    meta: indextg7duepunAMeta || {},
    alias: indextg7duepunAMeta?.alias || [],
    redirect: indextg7duepunAMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/index.vue").then(m => m.default || m)
  },
  {
    name: indextg7duepunAMeta?.name ?? "gar___fr",
    path: indextg7duepunAMeta?.path ?? "/fr/gar",
    meta: indextg7duepunAMeta || {},
    alias: indextg7duepunAMeta?.alias || [],
    redirect: indextg7duepunAMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/index.vue").then(m => m.default || m)
  },
  {
    name: redirect8zVNNhIZ9kMeta?.name ?? "gar-redirect___en",
    path: redirect8zVNNhIZ9kMeta?.path ?? "/en/gar/redirect",
    meta: redirect8zVNNhIZ9kMeta || {},
    alias: redirect8zVNNhIZ9kMeta?.alias || [],
    redirect: redirect8zVNNhIZ9kMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect8zVNNhIZ9kMeta?.name ?? "gar-redirect___de",
    path: redirect8zVNNhIZ9kMeta?.path ?? "/de/gar/redirect",
    meta: redirect8zVNNhIZ9kMeta || {},
    alias: redirect8zVNNhIZ9kMeta?.alias || [],
    redirect: redirect8zVNNhIZ9kMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect8zVNNhIZ9kMeta?.name ?? "gar-redirect___es",
    path: redirect8zVNNhIZ9kMeta?.path ?? "/es/gar/redirect",
    meta: redirect8zVNNhIZ9kMeta || {},
    alias: redirect8zVNNhIZ9kMeta?.alias || [],
    redirect: redirect8zVNNhIZ9kMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect8zVNNhIZ9kMeta?.name ?? "gar-redirect___fr",
    path: redirect8zVNNhIZ9kMeta?.path ?? "/fr/gar/redirect",
    meta: redirect8zVNNhIZ9kMeta || {},
    alias: redirect8zVNNhIZ9kMeta?.alias || [],
    redirect: redirect8zVNNhIZ9kMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/gar/redirect.vue").then(m => m.default || m)
  },
  {
    name: indexXZYzHytSb0Meta?.name ?? "index___en",
    path: indexXZYzHytSb0Meta?.path ?? "/en",
    meta: indexXZYzHytSb0Meta || {},
    alias: indexXZYzHytSb0Meta?.alias || [],
    redirect: indexXZYzHytSb0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZYzHytSb0Meta?.name ?? "index___de",
    path: indexXZYzHytSb0Meta?.path ?? "/de",
    meta: indexXZYzHytSb0Meta || {},
    alias: indexXZYzHytSb0Meta?.alias || [],
    redirect: indexXZYzHytSb0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZYzHytSb0Meta?.name ?? "index___es",
    path: indexXZYzHytSb0Meta?.path ?? "/es",
    meta: indexXZYzHytSb0Meta || {},
    alias: indexXZYzHytSb0Meta?.alias || [],
    redirect: indexXZYzHytSb0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexXZYzHytSb0Meta?.name ?? "index___fr",
    path: indexXZYzHytSb0Meta?.path ?? "/fr",
    meta: indexXZYzHytSb0Meta || {},
    alias: indexXZYzHytSb0Meta?.alias || [],
    redirect: indexXZYzHytSb0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: landingTMUvWStXAtMeta?.name ?? "landing___en",
    path: landingTMUvWStXAtMeta?.path ?? "/en/landing",
    meta: landingTMUvWStXAtMeta || {},
    alias: landingTMUvWStXAtMeta?.alias || [],
    redirect: landingTMUvWStXAtMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: landingTMUvWStXAtMeta?.name ?? "landing___de",
    path: landingTMUvWStXAtMeta?.path ?? "/de/landing",
    meta: landingTMUvWStXAtMeta || {},
    alias: landingTMUvWStXAtMeta?.alias || [],
    redirect: landingTMUvWStXAtMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: landingTMUvWStXAtMeta?.name ?? "landing___es",
    path: landingTMUvWStXAtMeta?.path ?? "/es/landing",
    meta: landingTMUvWStXAtMeta || {},
    alias: landingTMUvWStXAtMeta?.alias || [],
    redirect: landingTMUvWStXAtMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: landingTMUvWStXAtMeta?.name ?? "landing___fr",
    path: landingTMUvWStXAtMeta?.path ?? "/fr/landing",
    meta: landingTMUvWStXAtMeta || {},
    alias: landingTMUvWStXAtMeta?.alias || [],
    redirect: landingTMUvWStXAtMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: loginnWWtDVY6PNMeta?.name ?? "login___en",
    path: loginnWWtDVY6PNMeta?.path ?? "/en/login",
    meta: loginnWWtDVY6PNMeta || {},
    alias: loginnWWtDVY6PNMeta?.alias || [],
    redirect: loginnWWtDVY6PNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginnWWtDVY6PNMeta?.name ?? "login___de",
    path: loginnWWtDVY6PNMeta?.path ?? "/de/login",
    meta: loginnWWtDVY6PNMeta || {},
    alias: loginnWWtDVY6PNMeta?.alias || [],
    redirect: loginnWWtDVY6PNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginnWWtDVY6PNMeta?.name ?? "login___es",
    path: loginnWWtDVY6PNMeta?.path ?? "/es/login",
    meta: loginnWWtDVY6PNMeta || {},
    alias: loginnWWtDVY6PNMeta?.alias || [],
    redirect: loginnWWtDVY6PNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginnWWtDVY6PNMeta?.name ?? "login___fr",
    path: loginnWWtDVY6PNMeta?.path ?? "/fr/login",
    meta: loginnWWtDVY6PNMeta || {},
    alias: loginnWWtDVY6PNMeta?.alias || [],
    redirect: loginnWWtDVY6PNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45community73ShHgDcrLMeta?.name ?? "my-community___en",
    path: my_45community73ShHgDcrLMeta?.path ?? "/en/my-community",
    meta: my_45community73ShHgDcrLMeta || {},
    alias: my_45community73ShHgDcrLMeta?.alias || [],
    redirect: my_45community73ShHgDcrLMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-community.vue").then(m => m.default || m)
  },
  {
    name: my_45community73ShHgDcrLMeta?.name ?? "my-community___de",
    path: my_45community73ShHgDcrLMeta?.path ?? "/de/my-community",
    meta: my_45community73ShHgDcrLMeta || {},
    alias: my_45community73ShHgDcrLMeta?.alias || [],
    redirect: my_45community73ShHgDcrLMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-community.vue").then(m => m.default || m)
  },
  {
    name: my_45community73ShHgDcrLMeta?.name ?? "my-community___es",
    path: my_45community73ShHgDcrLMeta?.path ?? "/es/my-community",
    meta: my_45community73ShHgDcrLMeta || {},
    alias: my_45community73ShHgDcrLMeta?.alias || [],
    redirect: my_45community73ShHgDcrLMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-community.vue").then(m => m.default || m)
  },
  {
    name: my_45community73ShHgDcrLMeta?.name ?? "my-community___fr",
    path: my_45community73ShHgDcrLMeta?.path ?? "/fr/my-community",
    meta: my_45community73ShHgDcrLMeta || {},
    alias: my_45community73ShHgDcrLMeta?.alias || [],
    redirect: my_45community73ShHgDcrLMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-community.vue").then(m => m.default || m)
  },
  {
    name: _91_91uuid_93_93QfLGsIEhwmMeta?.name ?? "my-messages-uuid___en",
    path: _91_91uuid_93_93QfLGsIEhwmMeta?.path ?? "/en/my-messages/:uuid?",
    meta: _91_91uuid_93_93QfLGsIEhwmMeta || {},
    alias: _91_91uuid_93_93QfLGsIEhwmMeta?.alias || [],
    redirect: _91_91uuid_93_93QfLGsIEhwmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-messages/[[uuid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91uuid_93_93QfLGsIEhwmMeta?.name ?? "my-messages-uuid___de",
    path: _91_91uuid_93_93QfLGsIEhwmMeta?.path ?? "/de/my-messages/:uuid?",
    meta: _91_91uuid_93_93QfLGsIEhwmMeta || {},
    alias: _91_91uuid_93_93QfLGsIEhwmMeta?.alias || [],
    redirect: _91_91uuid_93_93QfLGsIEhwmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-messages/[[uuid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91uuid_93_93QfLGsIEhwmMeta?.name ?? "my-messages-uuid___es",
    path: _91_91uuid_93_93QfLGsIEhwmMeta?.path ?? "/es/my-messages/:uuid?",
    meta: _91_91uuid_93_93QfLGsIEhwmMeta || {},
    alias: _91_91uuid_93_93QfLGsIEhwmMeta?.alias || [],
    redirect: _91_91uuid_93_93QfLGsIEhwmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-messages/[[uuid]].vue").then(m => m.default || m)
  },
  {
    name: _91_91uuid_93_93QfLGsIEhwmMeta?.name ?? "my-messages-uuid___fr",
    path: _91_91uuid_93_93QfLGsIEhwmMeta?.path ?? "/fr/my-messages/:uuid?",
    meta: _91_91uuid_93_93QfLGsIEhwmMeta || {},
    alias: _91_91uuid_93_93QfLGsIEhwmMeta?.alias || [],
    redirect: _91_91uuid_93_93QfLGsIEhwmMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-messages/[[uuid]].vue").then(m => m.default || m)
  },
  {
    name: my_45profilea4hZbE51ugMeta?.name ?? "my-profile___en",
    path: my_45profilea4hZbE51ugMeta?.path ?? "/en/my-profile",
    meta: my_45profilea4hZbE51ugMeta || {},
    alias: my_45profilea4hZbE51ugMeta?.alias || [],
    redirect: my_45profilea4hZbE51ugMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: my_45profilea4hZbE51ugMeta?.name ?? "my-profile___de",
    path: my_45profilea4hZbE51ugMeta?.path ?? "/de/my-profile",
    meta: my_45profilea4hZbE51ugMeta || {},
    alias: my_45profilea4hZbE51ugMeta?.alias || [],
    redirect: my_45profilea4hZbE51ugMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: my_45profilea4hZbE51ugMeta?.name ?? "my-profile___es",
    path: my_45profilea4hZbE51ugMeta?.path ?? "/es/my-profile",
    meta: my_45profilea4hZbE51ugMeta || {},
    alias: my_45profilea4hZbE51ugMeta?.alias || [],
    redirect: my_45profilea4hZbE51ugMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: my_45profilea4hZbE51ugMeta?.name ?? "my-profile___fr",
    path: my_45profilea4hZbE51ugMeta?.path ?? "/fr/my-profile",
    meta: my_45profilea4hZbE51ugMeta || {},
    alias: my_45profilea4hZbE51ugMeta?.alias || [],
    redirect: my_45profilea4hZbE51ugMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: forgotStikWUYtmTMeta?.name ?? "password-forgot___en",
    path: forgotStikWUYtmTMeta?.path ?? "/en/password/forgot",
    meta: forgotStikWUYtmTMeta || {},
    alias: forgotStikWUYtmTMeta?.alias || [],
    redirect: forgotStikWUYtmTMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotStikWUYtmTMeta?.name ?? "password-forgot___de",
    path: forgotStikWUYtmTMeta?.path ?? "/de/password/forgot",
    meta: forgotStikWUYtmTMeta || {},
    alias: forgotStikWUYtmTMeta?.alias || [],
    redirect: forgotStikWUYtmTMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotStikWUYtmTMeta?.name ?? "password-forgot___es",
    path: forgotStikWUYtmTMeta?.path ?? "/es/password/forgot",
    meta: forgotStikWUYtmTMeta || {},
    alias: forgotStikWUYtmTMeta?.alias || [],
    redirect: forgotStikWUYtmTMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: forgotStikWUYtmTMeta?.name ?? "password-forgot___fr",
    path: forgotStikWUYtmTMeta?.path ?? "/fr/password/forgot",
    meta: forgotStikWUYtmTMeta || {},
    alias: forgotStikWUYtmTMeta?.alias || [],
    redirect: forgotStikWUYtmTMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/forgot.vue").then(m => m.default || m)
  },
  {
    name: _91token_93CAAeduVsKoMeta?.name ?? "password-reset-token___en",
    path: _91token_93CAAeduVsKoMeta?.path ?? "/en/password/reset/:token()",
    meta: _91token_93CAAeduVsKoMeta || {},
    alias: _91token_93CAAeduVsKoMeta?.alias || [],
    redirect: _91token_93CAAeduVsKoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93CAAeduVsKoMeta?.name ?? "password-reset-token___de",
    path: _91token_93CAAeduVsKoMeta?.path ?? "/de/password/reset/:token()",
    meta: _91token_93CAAeduVsKoMeta || {},
    alias: _91token_93CAAeduVsKoMeta?.alias || [],
    redirect: _91token_93CAAeduVsKoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93CAAeduVsKoMeta?.name ?? "password-reset-token___es",
    path: _91token_93CAAeduVsKoMeta?.path ?? "/es/password/reset/:token()",
    meta: _91token_93CAAeduVsKoMeta || {},
    alias: _91token_93CAAeduVsKoMeta?.alias || [],
    redirect: _91token_93CAAeduVsKoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93CAAeduVsKoMeta?.name ?? "password-reset-token___fr",
    path: _91token_93CAAeduVsKoMeta?.path ?? "/fr/password/reset/:token()",
    meta: _91token_93CAAeduVsKoMeta || {},
    alias: _91token_93CAAeduVsKoMeta?.alias || [],
    redirect: _91token_93CAAeduVsKoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93h2lfz2LCdIMeta?.name ?? "profile-uuid___en",
    path: _91uuid_93h2lfz2LCdIMeta?.path ?? "/en/profile/:uuid()",
    meta: _91uuid_93h2lfz2LCdIMeta || {},
    alias: _91uuid_93h2lfz2LCdIMeta?.alias || [],
    redirect: _91uuid_93h2lfz2LCdIMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/profile/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93h2lfz2LCdIMeta?.name ?? "profile-uuid___de",
    path: _91uuid_93h2lfz2LCdIMeta?.path ?? "/de/profile/:uuid()",
    meta: _91uuid_93h2lfz2LCdIMeta || {},
    alias: _91uuid_93h2lfz2LCdIMeta?.alias || [],
    redirect: _91uuid_93h2lfz2LCdIMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/profile/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93h2lfz2LCdIMeta?.name ?? "profile-uuid___es",
    path: _91uuid_93h2lfz2LCdIMeta?.path ?? "/es/profile/:uuid()",
    meta: _91uuid_93h2lfz2LCdIMeta || {},
    alias: _91uuid_93h2lfz2LCdIMeta?.alias || [],
    redirect: _91uuid_93h2lfz2LCdIMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/profile/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93h2lfz2LCdIMeta?.name ?? "profile-uuid___fr",
    path: _91uuid_93h2lfz2LCdIMeta?.path ?? "/fr/profile/:uuid()",
    meta: _91uuid_93h2lfz2LCdIMeta || {},
    alias: _91uuid_93h2lfz2LCdIMeta?.alias || [],
    redirect: _91uuid_93h2lfz2LCdIMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/profile/[uuid].vue").then(m => m.default || m)
  },
  {
    name: register_45codeJOZJaiUxwiMeta?.name ?? "register-code___en",
    path: register_45codeJOZJaiUxwiMeta?.path ?? "/en/register-code",
    meta: register_45codeJOZJaiUxwiMeta || {},
    alias: register_45codeJOZJaiUxwiMeta?.alias || [],
    redirect: register_45codeJOZJaiUxwiMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/register-code.vue").then(m => m.default || m)
  },
  {
    name: register_45codeJOZJaiUxwiMeta?.name ?? "register-code___de",
    path: register_45codeJOZJaiUxwiMeta?.path ?? "/de/register-code",
    meta: register_45codeJOZJaiUxwiMeta || {},
    alias: register_45codeJOZJaiUxwiMeta?.alias || [],
    redirect: register_45codeJOZJaiUxwiMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/register-code.vue").then(m => m.default || m)
  },
  {
    name: register_45codeJOZJaiUxwiMeta?.name ?? "register-code___es",
    path: register_45codeJOZJaiUxwiMeta?.path ?? "/es/register-code",
    meta: register_45codeJOZJaiUxwiMeta || {},
    alias: register_45codeJOZJaiUxwiMeta?.alias || [],
    redirect: register_45codeJOZJaiUxwiMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/register-code.vue").then(m => m.default || m)
  },
  {
    name: register_45codeJOZJaiUxwiMeta?.name ?? "register-code___fr",
    path: register_45codeJOZJaiUxwiMeta?.path ?? "/fr/register-code",
    meta: register_45codeJOZJaiUxwiMeta || {},
    alias: register_45codeJOZJaiUxwiMeta?.alias || [],
    redirect: register_45codeJOZJaiUxwiMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/register-code.vue").then(m => m.default || m)
  },
  {
    name: searchbRlcpZUwqoMeta?.name ?? "search___en",
    path: searchbRlcpZUwqoMeta?.path ?? "/en/search",
    meta: searchbRlcpZUwqoMeta || {},
    alias: searchbRlcpZUwqoMeta?.alias || [],
    redirect: searchbRlcpZUwqoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchbRlcpZUwqoMeta?.name ?? "search___de",
    path: searchbRlcpZUwqoMeta?.path ?? "/de/search",
    meta: searchbRlcpZUwqoMeta || {},
    alias: searchbRlcpZUwqoMeta?.alias || [],
    redirect: searchbRlcpZUwqoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchbRlcpZUwqoMeta?.name ?? "search___es",
    path: searchbRlcpZUwqoMeta?.path ?? "/es/search",
    meta: searchbRlcpZUwqoMeta || {},
    alias: searchbRlcpZUwqoMeta?.alias || [],
    redirect: searchbRlcpZUwqoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchbRlcpZUwqoMeta?.name ?? "search___fr",
    path: searchbRlcpZUwqoMeta?.path ?? "/fr/search",
    meta: searchbRlcpZUwqoMeta || {},
    alias: searchbRlcpZUwqoMeta?.alias || [],
    redirect: searchbRlcpZUwqoMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/search.vue").then(m => m.default || m)
  },
  {
    name: classancW4OV5I2Meta?.name ?? "student-class___en",
    path: classancW4OV5I2Meta?.path ?? "/en/student/class",
    meta: classancW4OV5I2Meta || {},
    alias: classancW4OV5I2Meta?.alias || [],
    redirect: classancW4OV5I2Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/class.vue").then(m => m.default || m)
  },
  {
    name: classancW4OV5I2Meta?.name ?? "student-class___de",
    path: classancW4OV5I2Meta?.path ?? "/de/student/class",
    meta: classancW4OV5I2Meta || {},
    alias: classancW4OV5I2Meta?.alias || [],
    redirect: classancW4OV5I2Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/class.vue").then(m => m.default || m)
  },
  {
    name: classancW4OV5I2Meta?.name ?? "student-class___es",
    path: classancW4OV5I2Meta?.path ?? "/es/student/class",
    meta: classancW4OV5I2Meta || {},
    alias: classancW4OV5I2Meta?.alias || [],
    redirect: classancW4OV5I2Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/class.vue").then(m => m.default || m)
  },
  {
    name: classancW4OV5I2Meta?.name ?? "student-class___fr",
    path: classancW4OV5I2Meta?.path ?? "/fr/student/class",
    meta: classancW4OV5I2Meta || {},
    alias: classancW4OV5I2Meta?.alias || [],
    redirect: classancW4OV5I2Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classFylin3qui0Meta?.name ?? "student-correspondent-class___en",
    path: correspondent_45classFylin3qui0Meta?.path ?? "/en/student/correspondent-class",
    meta: correspondent_45classFylin3qui0Meta || {},
    alias: correspondent_45classFylin3qui0Meta?.alias || [],
    redirect: correspondent_45classFylin3qui0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classFylin3qui0Meta?.name ?? "student-correspondent-class___de",
    path: correspondent_45classFylin3qui0Meta?.path ?? "/de/student/correspondent-class",
    meta: correspondent_45classFylin3qui0Meta || {},
    alias: correspondent_45classFylin3qui0Meta?.alias || [],
    redirect: correspondent_45classFylin3qui0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classFylin3qui0Meta?.name ?? "student-correspondent-class___es",
    path: correspondent_45classFylin3qui0Meta?.path ?? "/es/student/correspondent-class",
    meta: correspondent_45classFylin3qui0Meta || {},
    alias: correspondent_45classFylin3qui0Meta?.alias || [],
    redirect: correspondent_45classFylin3qui0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classFylin3qui0Meta?.name ?? "student-correspondent-class___fr",
    path: correspondent_45classFylin3qui0Meta?.path ?? "/fr/student/correspondent-class",
    meta: correspondent_45classFylin3qui0Meta || {},
    alias: correspondent_45classFylin3qui0Meta?.alias || [],
    redirect: correspondent_45classFylin3qui0Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: workspaceQxXGlraAv5Meta?.name ?? "student-workspace___en",
    path: workspaceQxXGlraAv5Meta?.path ?? "/en/student/workspace",
    meta: workspaceQxXGlraAv5Meta || {},
    alias: workspaceQxXGlraAv5Meta?.alias || [],
    redirect: workspaceQxXGlraAv5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/workspace.vue").then(m => m.default || m)
  },
  {
    name: workspaceQxXGlraAv5Meta?.name ?? "student-workspace___de",
    path: workspaceQxXGlraAv5Meta?.path ?? "/de/student/workspace",
    meta: workspaceQxXGlraAv5Meta || {},
    alias: workspaceQxXGlraAv5Meta?.alias || [],
    redirect: workspaceQxXGlraAv5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/workspace.vue").then(m => m.default || m)
  },
  {
    name: workspaceQxXGlraAv5Meta?.name ?? "student-workspace___es",
    path: workspaceQxXGlraAv5Meta?.path ?? "/es/student/workspace",
    meta: workspaceQxXGlraAv5Meta || {},
    alias: workspaceQxXGlraAv5Meta?.alias || [],
    redirect: workspaceQxXGlraAv5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/workspace.vue").then(m => m.default || m)
  },
  {
    name: workspaceQxXGlraAv5Meta?.name ?? "student-workspace___fr",
    path: workspaceQxXGlraAv5Meta?.path ?? "/fr/student/workspace",
    meta: workspaceQxXGlraAv5Meta || {},
    alias: workspaceQxXGlraAv5Meta?.alias || [],
    redirect: workspaceQxXGlraAv5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/student/workspace.vue").then(m => m.default || m)
  },
  {
    name: add_45studentso4CyRqsrH9Meta?.name ?? "teacher-add-students___en",
    path: add_45studentso4CyRqsrH9Meta?.path ?? "/en/teacher/add-students",
    meta: add_45studentso4CyRqsrH9Meta || {},
    alias: add_45studentso4CyRqsrH9Meta?.alias || [],
    redirect: add_45studentso4CyRqsrH9Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/add-students.vue").then(m => m.default || m)
  },
  {
    name: add_45studentso4CyRqsrH9Meta?.name ?? "teacher-add-students___de",
    path: add_45studentso4CyRqsrH9Meta?.path ?? "/de/teacher/add-students",
    meta: add_45studentso4CyRqsrH9Meta || {},
    alias: add_45studentso4CyRqsrH9Meta?.alias || [],
    redirect: add_45studentso4CyRqsrH9Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/add-students.vue").then(m => m.default || m)
  },
  {
    name: add_45studentso4CyRqsrH9Meta?.name ?? "teacher-add-students___es",
    path: add_45studentso4CyRqsrH9Meta?.path ?? "/es/teacher/add-students",
    meta: add_45studentso4CyRqsrH9Meta || {},
    alias: add_45studentso4CyRqsrH9Meta?.alias || [],
    redirect: add_45studentso4CyRqsrH9Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/add-students.vue").then(m => m.default || m)
  },
  {
    name: add_45studentso4CyRqsrH9Meta?.name ?? "teacher-add-students___fr",
    path: add_45studentso4CyRqsrH9Meta?.path ?? "/fr/teacher/add-students",
    meta: add_45studentso4CyRqsrH9Meta || {},
    alias: add_45studentso4CyRqsrH9Meta?.alias || [],
    redirect: add_45studentso4CyRqsrH9Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/add-students.vue").then(m => m.default || m)
  },
  {
    name: class_45tests34EwswhrTUMeta?.name ?? "teacher-class-tests___en",
    path: class_45tests34EwswhrTUMeta?.path ?? "/en/teacher/class-tests",
    meta: class_45tests34EwswhrTUMeta || {},
    alias: class_45tests34EwswhrTUMeta?.alias || [],
    redirect: class_45tests34EwswhrTUMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class-tests.vue").then(m => m.default || m)
  },
  {
    name: class_45tests34EwswhrTUMeta?.name ?? "teacher-class-tests___de",
    path: class_45tests34EwswhrTUMeta?.path ?? "/de/teacher/class-tests",
    meta: class_45tests34EwswhrTUMeta || {},
    alias: class_45tests34EwswhrTUMeta?.alias || [],
    redirect: class_45tests34EwswhrTUMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class-tests.vue").then(m => m.default || m)
  },
  {
    name: class_45tests34EwswhrTUMeta?.name ?? "teacher-class-tests___es",
    path: class_45tests34EwswhrTUMeta?.path ?? "/es/teacher/class-tests",
    meta: class_45tests34EwswhrTUMeta || {},
    alias: class_45tests34EwswhrTUMeta?.alias || [],
    redirect: class_45tests34EwswhrTUMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class-tests.vue").then(m => m.default || m)
  },
  {
    name: class_45tests34EwswhrTUMeta?.name ?? "teacher-class-tests___fr",
    path: class_45tests34EwswhrTUMeta?.path ?? "/fr/teacher/class-tests",
    meta: class_45tests34EwswhrTUMeta || {},
    alias: class_45tests34EwswhrTUMeta?.alias || [],
    redirect: class_45tests34EwswhrTUMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class-tests.vue").then(m => m.default || m)
  },
  {
    name: classDg1iEvfYfKMeta?.name ?? "teacher-class___en",
    path: classDg1iEvfYfKMeta?.path ?? "/en/teacher/class",
    meta: classDg1iEvfYfKMeta || {},
    alias: classDg1iEvfYfKMeta?.alias || [],
    redirect: classDg1iEvfYfKMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class.vue").then(m => m.default || m)
  },
  {
    name: classDg1iEvfYfKMeta?.name ?? "teacher-class___de",
    path: classDg1iEvfYfKMeta?.path ?? "/de/teacher/class",
    meta: classDg1iEvfYfKMeta || {},
    alias: classDg1iEvfYfKMeta?.alias || [],
    redirect: classDg1iEvfYfKMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class.vue").then(m => m.default || m)
  },
  {
    name: classDg1iEvfYfKMeta?.name ?? "teacher-class___es",
    path: classDg1iEvfYfKMeta?.path ?? "/es/teacher/class",
    meta: classDg1iEvfYfKMeta || {},
    alias: classDg1iEvfYfKMeta?.alias || [],
    redirect: classDg1iEvfYfKMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class.vue").then(m => m.default || m)
  },
  {
    name: classDg1iEvfYfKMeta?.name ?? "teacher-class___fr",
    path: classDg1iEvfYfKMeta?.path ?? "/fr/teacher/class",
    meta: classDg1iEvfYfKMeta || {},
    alias: classDg1iEvfYfKMeta?.alias || [],
    redirect: classDg1iEvfYfKMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classG6CoGlXERaMeta?.name ?? "teacher-correspondent-class___en",
    path: correspondent_45classG6CoGlXERaMeta?.path ?? "/en/teacher/correspondent-class",
    meta: correspondent_45classG6CoGlXERaMeta || {},
    alias: correspondent_45classG6CoGlXERaMeta?.alias || [],
    redirect: correspondent_45classG6CoGlXERaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classG6CoGlXERaMeta?.name ?? "teacher-correspondent-class___de",
    path: correspondent_45classG6CoGlXERaMeta?.path ?? "/de/teacher/correspondent-class",
    meta: correspondent_45classG6CoGlXERaMeta || {},
    alias: correspondent_45classG6CoGlXERaMeta?.alias || [],
    redirect: correspondent_45classG6CoGlXERaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classG6CoGlXERaMeta?.name ?? "teacher-correspondent-class___es",
    path: correspondent_45classG6CoGlXERaMeta?.path ?? "/es/teacher/correspondent-class",
    meta: correspondent_45classG6CoGlXERaMeta || {},
    alias: correspondent_45classG6CoGlXERaMeta?.alias || [],
    redirect: correspondent_45classG6CoGlXERaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: correspondent_45classG6CoGlXERaMeta?.name ?? "teacher-correspondent-class___fr",
    path: correspondent_45classG6CoGlXERaMeta?.path ?? "/fr/teacher/correspondent-class",
    meta: correspondent_45classG6CoGlXERaMeta || {},
    alias: correspondent_45classG6CoGlXERaMeta?.alias || [],
    redirect: correspondent_45classG6CoGlXERaMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: create_45correspondent_45classh3Z8JX82TfMeta?.name ?? "teacher-create-correspondent-class___en",
    path: create_45correspondent_45classh3Z8JX82TfMeta?.path ?? "/en/teacher/create-correspondent-class",
    meta: create_45correspondent_45classh3Z8JX82TfMeta || {},
    alias: create_45correspondent_45classh3Z8JX82TfMeta?.alias || [],
    redirect: create_45correspondent_45classh3Z8JX82TfMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: create_45correspondent_45classh3Z8JX82TfMeta?.name ?? "teacher-create-correspondent-class___de",
    path: create_45correspondent_45classh3Z8JX82TfMeta?.path ?? "/de/teacher/create-correspondent-class",
    meta: create_45correspondent_45classh3Z8JX82TfMeta || {},
    alias: create_45correspondent_45classh3Z8JX82TfMeta?.alias || [],
    redirect: create_45correspondent_45classh3Z8JX82TfMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: create_45correspondent_45classh3Z8JX82TfMeta?.name ?? "teacher-create-correspondent-class___es",
    path: create_45correspondent_45classh3Z8JX82TfMeta?.path ?? "/es/teacher/create-correspondent-class",
    meta: create_45correspondent_45classh3Z8JX82TfMeta || {},
    alias: create_45correspondent_45classh3Z8JX82TfMeta?.alias || [],
    redirect: create_45correspondent_45classh3Z8JX82TfMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: create_45correspondent_45classh3Z8JX82TfMeta?.name ?? "teacher-create-correspondent-class___fr",
    path: create_45correspondent_45classh3Z8JX82TfMeta?.path ?? "/fr/teacher/create-correspondent-class",
    meta: create_45correspondent_45classh3Z8JX82TfMeta || {},
    alias: create_45correspondent_45classh3Z8JX82TfMeta?.alias || [],
    redirect: create_45correspondent_45classh3Z8JX82TfMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-correspondent-class.vue").then(m => m.default || m)
  },
  {
    name: create_45work_45groupPXYV2ofRnGMeta?.name ?? "teacher-create-work-group___en",
    path: create_45work_45groupPXYV2ofRnGMeta?.path ?? "/en/teacher/create-work-group",
    meta: create_45work_45groupPXYV2ofRnGMeta || {},
    alias: create_45work_45groupPXYV2ofRnGMeta?.alias || [],
    redirect: create_45work_45groupPXYV2ofRnGMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-work-group.vue").then(m => m.default || m)
  },
  {
    name: create_45work_45groupPXYV2ofRnGMeta?.name ?? "teacher-create-work-group___de",
    path: create_45work_45groupPXYV2ofRnGMeta?.path ?? "/de/teacher/create-work-group",
    meta: create_45work_45groupPXYV2ofRnGMeta || {},
    alias: create_45work_45groupPXYV2ofRnGMeta?.alias || [],
    redirect: create_45work_45groupPXYV2ofRnGMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-work-group.vue").then(m => m.default || m)
  },
  {
    name: create_45work_45groupPXYV2ofRnGMeta?.name ?? "teacher-create-work-group___es",
    path: create_45work_45groupPXYV2ofRnGMeta?.path ?? "/es/teacher/create-work-group",
    meta: create_45work_45groupPXYV2ofRnGMeta || {},
    alias: create_45work_45groupPXYV2ofRnGMeta?.alias || [],
    redirect: create_45work_45groupPXYV2ofRnGMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-work-group.vue").then(m => m.default || m)
  },
  {
    name: create_45work_45groupPXYV2ofRnGMeta?.name ?? "teacher-create-work-group___fr",
    path: create_45work_45groupPXYV2ofRnGMeta?.path ?? "/fr/teacher/create-work-group",
    meta: create_45work_45groupPXYV2ofRnGMeta || {},
    alias: create_45work_45groupPXYV2ofRnGMeta?.alias || [],
    redirect: create_45work_45groupPXYV2ofRnGMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/create-work-group.vue").then(m => m.default || m)
  },
  {
    name: edit_45activityRK523ShZ4mMeta?.name ?? "teacher-edit-activity___en",
    path: edit_45activityRK523ShZ4mMeta?.path ?? "/en/teacher/edit-activity",
    meta: edit_45activityRK523ShZ4mMeta || {},
    alias: edit_45activityRK523ShZ4mMeta?.alias || [],
    redirect: edit_45activityRK523ShZ4mMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-activity.vue").then(m => m.default || m)
  },
  {
    name: edit_45activityRK523ShZ4mMeta?.name ?? "teacher-edit-activity___de",
    path: edit_45activityRK523ShZ4mMeta?.path ?? "/de/teacher/edit-activity",
    meta: edit_45activityRK523ShZ4mMeta || {},
    alias: edit_45activityRK523ShZ4mMeta?.alias || [],
    redirect: edit_45activityRK523ShZ4mMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-activity.vue").then(m => m.default || m)
  },
  {
    name: edit_45activityRK523ShZ4mMeta?.name ?? "teacher-edit-activity___es",
    path: edit_45activityRK523ShZ4mMeta?.path ?? "/es/teacher/edit-activity",
    meta: edit_45activityRK523ShZ4mMeta || {},
    alias: edit_45activityRK523ShZ4mMeta?.alias || [],
    redirect: edit_45activityRK523ShZ4mMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-activity.vue").then(m => m.default || m)
  },
  {
    name: edit_45activityRK523ShZ4mMeta?.name ?? "teacher-edit-activity___fr",
    path: edit_45activityRK523ShZ4mMeta?.path ?? "/fr/teacher/edit-activity",
    meta: edit_45activityRK523ShZ4mMeta || {},
    alias: edit_45activityRK523ShZ4mMeta?.alias || [],
    redirect: edit_45activityRK523ShZ4mMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-activity.vue").then(m => m.default || m)
  },
  {
    name: edit_45work_45groupkXAR0DAvR7Meta?.name ?? "teacher-edit-work-group___en",
    path: edit_45work_45groupkXAR0DAvR7Meta?.path ?? "/en/teacher/edit-work-group",
    meta: edit_45work_45groupkXAR0DAvR7Meta || {},
    alias: edit_45work_45groupkXAR0DAvR7Meta?.alias || [],
    redirect: edit_45work_45groupkXAR0DAvR7Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-work-group.vue").then(m => m.default || m)
  },
  {
    name: edit_45work_45groupkXAR0DAvR7Meta?.name ?? "teacher-edit-work-group___de",
    path: edit_45work_45groupkXAR0DAvR7Meta?.path ?? "/de/teacher/edit-work-group",
    meta: edit_45work_45groupkXAR0DAvR7Meta || {},
    alias: edit_45work_45groupkXAR0DAvR7Meta?.alias || [],
    redirect: edit_45work_45groupkXAR0DAvR7Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-work-group.vue").then(m => m.default || m)
  },
  {
    name: edit_45work_45groupkXAR0DAvR7Meta?.name ?? "teacher-edit-work-group___es",
    path: edit_45work_45groupkXAR0DAvR7Meta?.path ?? "/es/teacher/edit-work-group",
    meta: edit_45work_45groupkXAR0DAvR7Meta || {},
    alias: edit_45work_45groupkXAR0DAvR7Meta?.alias || [],
    redirect: edit_45work_45groupkXAR0DAvR7Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-work-group.vue").then(m => m.default || m)
  },
  {
    name: edit_45work_45groupkXAR0DAvR7Meta?.name ?? "teacher-edit-work-group___fr",
    path: edit_45work_45groupkXAR0DAvR7Meta?.path ?? "/fr/teacher/edit-work-group",
    meta: edit_45work_45groupkXAR0DAvR7Meta || {},
    alias: edit_45work_45groupkXAR0DAvR7Meta?.alias || [],
    redirect: edit_45work_45groupkXAR0DAvR7Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/edit-work-group.vue").then(m => m.default || m)
  },
  {
    name: manage_45studentsFMSQ5ww6sNMeta?.name ?? "teacher-manage-students___en",
    path: manage_45studentsFMSQ5ww6sNMeta?.path ?? "/en/teacher/manage-students",
    meta: manage_45studentsFMSQ5ww6sNMeta || {},
    alias: manage_45studentsFMSQ5ww6sNMeta?.alias || [],
    redirect: manage_45studentsFMSQ5ww6sNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/manage-students.vue").then(m => m.default || m)
  },
  {
    name: manage_45studentsFMSQ5ww6sNMeta?.name ?? "teacher-manage-students___de",
    path: manage_45studentsFMSQ5ww6sNMeta?.path ?? "/de/teacher/manage-students",
    meta: manage_45studentsFMSQ5ww6sNMeta || {},
    alias: manage_45studentsFMSQ5ww6sNMeta?.alias || [],
    redirect: manage_45studentsFMSQ5ww6sNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/manage-students.vue").then(m => m.default || m)
  },
  {
    name: manage_45studentsFMSQ5ww6sNMeta?.name ?? "teacher-manage-students___es",
    path: manage_45studentsFMSQ5ww6sNMeta?.path ?? "/es/teacher/manage-students",
    meta: manage_45studentsFMSQ5ww6sNMeta || {},
    alias: manage_45studentsFMSQ5ww6sNMeta?.alias || [],
    redirect: manage_45studentsFMSQ5ww6sNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/manage-students.vue").then(m => m.default || m)
  },
  {
    name: manage_45studentsFMSQ5ww6sNMeta?.name ?? "teacher-manage-students___fr",
    path: manage_45studentsFMSQ5ww6sNMeta?.path ?? "/fr/teacher/manage-students",
    meta: manage_45studentsFMSQ5ww6sNMeta || {},
    alias: manage_45studentsFMSQ5ww6sNMeta?.alias || [],
    redirect: manage_45studentsFMSQ5ww6sNMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/manage-students.vue").then(m => m.default || m)
  },
  {
    name: workspace1ojPHFwcD5Meta?.name ?? "teacher-workspace___en",
    path: workspace1ojPHFwcD5Meta?.path ?? "/en/teacher/workspace",
    meta: workspace1ojPHFwcD5Meta || {},
    alias: workspace1ojPHFwcD5Meta?.alias || [],
    redirect: workspace1ojPHFwcD5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/workspace.vue").then(m => m.default || m)
  },
  {
    name: workspace1ojPHFwcD5Meta?.name ?? "teacher-workspace___de",
    path: workspace1ojPHFwcD5Meta?.path ?? "/de/teacher/workspace",
    meta: workspace1ojPHFwcD5Meta || {},
    alias: workspace1ojPHFwcD5Meta?.alias || [],
    redirect: workspace1ojPHFwcD5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/workspace.vue").then(m => m.default || m)
  },
  {
    name: workspace1ojPHFwcD5Meta?.name ?? "teacher-workspace___es",
    path: workspace1ojPHFwcD5Meta?.path ?? "/es/teacher/workspace",
    meta: workspace1ojPHFwcD5Meta || {},
    alias: workspace1ojPHFwcD5Meta?.alias || [],
    redirect: workspace1ojPHFwcD5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/workspace.vue").then(m => m.default || m)
  },
  {
    name: workspace1ojPHFwcD5Meta?.name ?? "teacher-workspace___fr",
    path: workspace1ojPHFwcD5Meta?.path ?? "/fr/teacher/workspace",
    meta: workspace1ojPHFwcD5Meta || {},
    alias: workspace1ojPHFwcD5Meta?.alias || [],
    redirect: workspace1ojPHFwcD5Meta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/teacher/workspace.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93pO43DLnzNdMeta?.name ?? "tests-iso-id___en",
    path: _91_91id_93_93pO43DLnzNdMeta?.path ?? "/en/tests/:iso?/:id?",
    meta: _91_91id_93_93pO43DLnzNdMeta || {},
    alias: _91_91id_93_93pO43DLnzNdMeta?.alias || [],
    redirect: _91_91id_93_93pO43DLnzNdMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/tests/[[iso]]/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93pO43DLnzNdMeta?.name ?? "tests-iso-id___de",
    path: _91_91id_93_93pO43DLnzNdMeta?.path ?? "/de/tests/:iso?/:id?",
    meta: _91_91id_93_93pO43DLnzNdMeta || {},
    alias: _91_91id_93_93pO43DLnzNdMeta?.alias || [],
    redirect: _91_91id_93_93pO43DLnzNdMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/tests/[[iso]]/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93pO43DLnzNdMeta?.name ?? "tests-iso-id___es",
    path: _91_91id_93_93pO43DLnzNdMeta?.path ?? "/es/tests/:iso?/:id?",
    meta: _91_91id_93_93pO43DLnzNdMeta || {},
    alias: _91_91id_93_93pO43DLnzNdMeta?.alias || [],
    redirect: _91_91id_93_93pO43DLnzNdMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/tests/[[iso]]/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93pO43DLnzNdMeta?.name ?? "tests-iso-id___fr",
    path: _91_91id_93_93pO43DLnzNdMeta?.path ?? "/fr/tests/:iso?/:id?",
    meta: _91_91id_93_93pO43DLnzNdMeta || {},
    alias: _91_91id_93_93pO43DLnzNdMeta?.alias || [],
    redirect: _91_91id_93_93pO43DLnzNdMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/tests/[[iso]]/[[id]].vue").then(m => m.default || m)
  },
  {
    name: wizardZ4TFdZqD9XMeta?.name ?? "wizard___en",
    path: wizardZ4TFdZqD9XMeta?.path ?? "/en/wizard",
    meta: wizardZ4TFdZqD9XMeta || {},
    alias: wizardZ4TFdZqD9XMeta?.alias || [],
    redirect: wizardZ4TFdZqD9XMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/wizard.vue").then(m => m.default || m)
  },
  {
    name: wizardZ4TFdZqD9XMeta?.name ?? "wizard___de",
    path: wizardZ4TFdZqD9XMeta?.path ?? "/de/wizard",
    meta: wizardZ4TFdZqD9XMeta || {},
    alias: wizardZ4TFdZqD9XMeta?.alias || [],
    redirect: wizardZ4TFdZqD9XMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/wizard.vue").then(m => m.default || m)
  },
  {
    name: wizardZ4TFdZqD9XMeta?.name ?? "wizard___es",
    path: wizardZ4TFdZqD9XMeta?.path ?? "/es/wizard",
    meta: wizardZ4TFdZqD9XMeta || {},
    alias: wizardZ4TFdZqD9XMeta?.alias || [],
    redirect: wizardZ4TFdZqD9XMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/wizard.vue").then(m => m.default || m)
  },
  {
    name: wizardZ4TFdZqD9XMeta?.name ?? "wizard___fr",
    path: wizardZ4TFdZqD9XMeta?.path ?? "/fr/wizard",
    meta: wizardZ4TFdZqD9XMeta || {},
    alias: wizardZ4TFdZqD9XMeta?.alias || [],
    redirect: wizardZ4TFdZqD9XMeta?.redirect,
    component: () => import("/var/www/vhosts/beegup.com/app.beegup.com/pages/wizard.vue").then(m => m.default || m)
  }
]